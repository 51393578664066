import React from 'react';

import { Inline } from '@atlaskit/primitives/compiled';

// eslint-disable-next-line @atlassian/tangerine/import/no-parent-imports
import { AIButton } from '../ai-button';
// eslint-disable-next-line @atlassian/tangerine/import/no-parent-imports
import { useDynamicSurfacePanel } from '../ai-dynamic-surface/useDynamicSurfacePanel';
// eslint-disable-next-line @atlassian/tangerine/import/no-parent-imports
import { AIProactiveNudge } from '../ai-proactive-nudge';
// eslint-disable-next-line @atlassian/tangerine/import/no-parent-imports
import type { ObjectSidebar } from '../common/types/object-sidebar-types';

export type AISmartButtonProps = {
	objectSidebar: ObjectSidebar;
};

export const AISmartButton = ({ objectSidebar }: AISmartButtonProps) => {
	const dynamicSurfacePanel = useDynamicSurfacePanel(objectSidebar);

	const onAISmartButtonClick = React.useCallback(() => {
		const { isObjectSidebarShown, panel } = objectSidebar.state;
		const { hideObjectSidebar, showObjectSidebar } = objectSidebar.actions;
		if (isObjectSidebarShown && panel?.id === dynamicSurfacePanel.id) {
			hideObjectSidebar();
		} else {
			showObjectSidebar(dynamicSurfacePanel, 'push');
		}
	}, [dynamicSurfacePanel, objectSidebar]);

	return (
		<Inline space="space.100" alignBlock="center">
			<AIProactiveNudge />
			<AIButton onClick={onAISmartButtonClick} />
		</Inline>
	);
};
